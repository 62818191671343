/*
  Authors : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 16/10/2023 17:32:47
*/




.VideoList .iq-card-body{
  height: 70vh;
  overflow: hidden;
  overflow-y: auto;
}
