/*
  Authors : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 16/10/2023 17:32:47
*/

.VideoItem {}

.VideoItem .iq-movie{
}
.VideoItem .media-body .video-name{
  white-space: nowrap; /* Empêche les retours à la ligne automatiques */
  overflow: hidden;    /* Cache tout texte qui dépasse les deux lignes */
  text-overflow: ellipsis; /* Facultatif : ajoute des points de suspension (...) si le texte est tronqué */
}
.VideoItem .media-body{

  /* width: 70%; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;

}
@media screen and (max-width: 1500px) {
  .VideoItem .media .iq-movie *,
  .VideoItem .media .iq-movie{
    width: 100%;
  }
  .VideoItem .media{
    flex-direction: column;
  }
  .VideoItem .media-body,
  .VideoItem .media-body{
    /* border: 1px solid red; */
    width: 100%;
  }
}
