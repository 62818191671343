/*
  Authors : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 08/10/2023 17:39:31
*/

.NotificationComponent {
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* border: 2px solid red; */
  z-index: 9999;
}

