/*
  Authors : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 07/10/2023 20:53:45
*/

.SignUp {}
.SignUp .error{
  color: red;
}
