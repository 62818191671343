/*
  Authors : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 15/10/2023 10:42:10
*/

.SelectData {}

.select2-selection__rendered,
.select2-results__option *{
  background-color: var(--iq-body-bg);
  border: 0;
}
.select2-results__option {
  
}