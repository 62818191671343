/*
  Authors : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 13/10/2023 19:00:11
*/

.VerifyCode {}
.verify-code input{
  text-align: center;
}
