/*
  Authors : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 07/10/2023 21:19:52
*/

.DataList {}

.iq-card .iq-card-header div.d-flex{
  gap: 10px;
  justify-content: center;
}
.iq-card-footer{
  justify-content: flex-end;
  gap: 10px;
  padding: 0 20px;
  align-items: center;
}
