/*
  Authors : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 23/10/2023 15:18:22
*/

.ChoosePlan {}
.ChoosePlan h2{
  color: red !important;
}
.ChoosePlan .formule-item button:hover{
  color: white !important;
}
.ChoosePlan small{
  text-align: center;
}

