/*
  Authors : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 24/10/2023 14:40:16
*/

.SubscriptionSwitch {}

.ouitube-plan{
  border: 1px solid ;
  display: flex;
  justify-content: center;
  padding: 3px 5px;
  border-radius: 55px;
}
.ouitube-plan > button{
  border-radius: 55px;
  padding: 3px 10px;
  cursor: pointer;
  margin: 2px;
}

.ouitube-plan .current-item{
  background-color: rgb(239, 7, 7) !important;
  color: white !important;
}
