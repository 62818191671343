body.light, body.light * {
  background-color: white !important;
  color: black !important;
}
body.light .logo{
  color: red;
}
body.light input,
body.light textarea, 
body.light select
{
   border: 1px solid #1d1d1d;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.px1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.group {
  display: flex;
  justify-content: space-between;
} */
