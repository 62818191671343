/*
  Authors : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 11/10/2023 19:00:49
*/

.ProgressBar {
  width: 100%;
}
