/*
  Authors : Mudey Formation
  Website : https://mudey.fr/
  App Name : E-commerce with React.Js
  Created At : 25/10/2023 13:50:51
*/

.Home {}
/* Réinitialisation de la mise en page et des marges par défaut */
body, h1, h2, h3, p, ul, li {
  margin: 0;
  padding: 0;
}

/* Style de base de la page */
body {
  /* font-family: Arial, sans-serif; */
  /* background-color: #f0f0f0; */
  /* color: #333; */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Barre de navigation */
header {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
}

/* Styles pour les liens de navigation */
header a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

/* Section héro */
.hero {
  text-align: center;
  padding: 100px 0;
  /* background-image: url('hero-background.jpg'); */
  background-size: cover;
  color: #fff;
  height: 500px;
}

.hero h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.hero p {
  font-size: 18px;
  margin-bottom: 30px;
}

/* Boutons CTA */
.hero a {
  background-color: #f00; /* Couleur du bouton */
  color: #fff;
  padding: 10px 20px;
  font-size: 18px;
  text-decoration: none;
  margin: 0 20px;
  border-radius: 5px;
}

/* Styles de la section de contenu en vedette */
.featured-content {
  padding: 40px 0;
  color: #222;
  background-color: #fff;
}

.featured-content h2 {
  font-size: 24px;
  color: #222;
  margin-bottom: 20px;
}


/* Section d'abonnement */
.subscription {
  text-align: center;
  padding: 60px 0;
  color: #222;
  background-color: #f0f0f0;
}

.subscription h2 {
  font-size: 24px;
  color: #222;
  margin-bottom: 20px;
}

.subscription p {
  font-size: 18px;
  margin-bottom: 30px;
}

.subscription a {
  background-color: #f00; /* Couleur du bouton */
  color: #fff;
  padding: 15px 30px;
  font-size: 20px;
  text-decoration: none;
  border-radius: 5px;
}

/* Pied de page */
.footer {
  background-color: #333;
  color: #fff;
  padding: 40px 0;
}

.footer h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.footer p {
  font-size: 16px;
  margin-bottom: 10px;
}

.footer ul {
  list-style: none;
}

.footer ul li {
  margin-bottom: 10px;
}

/* Barre de bas de page */
.footer-bottom {
  background-color: #222;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}
